import React, { FC, useRef } from "react";
import ReactResizeDetector from "react-resize-detector";
import { fontStacks, colors, borders } from "../global-style";
import { graphql } from "gatsby";
import { FluidObject } from "gatsby-image";
import { transparentize } from "polished";
import { NewsPageQuery } from "../../graphql-types";

import { BackgroundBlockAbs } from "../components/backgroundblockabs";
import { HorizontalCard } from "../components/horizontalcard";
import { Card } from "../components/card";

export const query = graphql`
  query NewsPage {
    image: file(relativePath: { eq: "men-s-white-dress-shirt-901424.png" }) {
      id
      childImageSharp {
        fluid(webpQuality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    cardlogo: file(relativePath: { eq: "logo-fd.png" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    wp {
      pressItems(first: 9999) {
        edges {
          node {
            title
            uri
            featuredImage {
              
                sourceUrl
                localFile {
                  childImageSharp {
                    fluid(webpQuality: 80) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              
            }
          }
        }
      }
      publicationPages(first: 9999) {
        edges {
          node {
            title
            uri
          }
        }
      }
      page(id: "/nieuws/", idType: URI) {
        title
        nieuwsCustomFields {
          intro {
            content
            title
          }
          list {
            title
            items {
              item
            }
          }
        }
      }
      newsPages(first: 9999) {
        edges {
          node {
            title
            uri
            excerpt
            newsPageCustomFields {
              publicationDate
              deletionDate
            }
            featuredImage {
           
                sourceUrl
                localFile {
                  childImageSharp {
                    fluid(webpQuality: 80) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            
          }
        }
      }
    }
  }
`;

const PageNieuws: FC<{ data: NewsPageQuery }> = props => {
  const leftElement = useRef<any>(null);
  const quickElement = useRef<any>(null);
  const quickInnerElement = useRef<any>(null);
  return (
    <ReactResizeDetector handleWidth={true}>
      {() => {
        const leftElementRight = leftElement.current
          ? leftElement.current.getBoundingClientRect().right
          : 0;

        const leftElementRightPx = leftElementRight + "px";

        const quickElementX = quickElement.current
          ? quickElement.current.getBoundingClientRect().x
          : 0;

        const quickInnerElementHeight = quickInnerElement.current
          ? quickInnerElement.current.getBoundingClientRect().height
          : 0;

        return (
          <>
            <div
              style={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                marginBottom: "12rem",
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  flexBasis: "83rem",
                  paddingTop: "1.5rem",
                  border: borders.red,
                }}
              >
                <div
                  style={{
                    margin: "0 1.5rem",
                  }}
                >
                  <div
                    style={{
                      marginBottom: "2rem",
                      lineHeight: "2.75rem",
                      display: "flex",
                      overflow: "hidden",
                      width: "100%",
                      maxWidth: "30rem",
                      border: borders.green,
                    }}
                  >
                    <div
                      style={{
                        fontFamily: fontStacks.merriweather,
                        fontSize: "2rem",
                        margin: "0.5rem",
                      }}
                    >
                      <div style={{ flexBasis: "30rem" }}>
                        {props.data.wp.page?.nieuwsCustomFields?.intro?.title}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    zIndex: 5,
                    display: "flex",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                  }}
                >
                  <div
                    ref={leftElement}
                    style={{
                      flexBasis: "50rem",
                      overflow: "hidden",
                    }}
                  >
                    <BackgroundBlockAbs
                      maxWidth={`calc(${leftElementRightPx} + 5rem)`}
                      contentText={
                        props.data.wp.page?.nieuwsCustomFields?.intro
                          ?.content as string
                      }
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              style={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                marginBottom: "12rem",
                overflow: "hidden",
              }}
            >
              <div
                css={`
                  @media (max-width: 768px) {
                    #display-toggle {
                      display: none;
                    }

                    #display-toggle-2 {
                      display: none !important;
                    }
                  }
                `}
                style={{
                  flexBasis: "83rem",
                  padding: "3.5rem 1.5rem 1.5rem 1.5rem",
                  border: borders.red,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flexBasis: "58rem",
                    margin: "0.5rem",
                    border: borders.green,
                  }}
                >
                  {/* <div
                    style={{
                      fontFamily: fontStacks.merriweather,
                      fontSize: "2rem",
                      marginBottom: "3rem",
                    }}
                  >
                    Nieuws
                  </div> */}
                  {props.data.wp.newsPages?.edges?.map((edge, i) => {
                    if (typeof document === "undefined") return;
                    const publicationDate = dateFromString(
                      edge.node.newsPageCustomFields.publicationDate
                    );
                    const deletionDate = dateFromString(
                      edge.node.newsPageCustomFields.deletionDate
                    );
                    const now = new Date().getTime();

                    return (
                      edge?.node &&
                      publicationDate.getTime() <= now &&
                      deletionDate.getTime() > now && (
                        <HorizontalCard
                          key={i}
                          img={
                            edge.node.featuredImage?.localFile.childImageSharp
                              .fluid as FluidObject
                          }
                          link={`/${edge.node.uri}`}
                          title={edge.node.title as string}
                          date={publicationDate.toLocaleDateString("nl-NL")}
                          content={edge.node.excerpt}
                        />
                      )
                    );
                  })}
                </div>
              </div>
            </div>

            <div
              style={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                marginBottom: "6rem",
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  flexBasis: "83rem",
                  padding: "1.5rem",
                  border: borders.red,
                }}
              >
                <div
                  style={{
                    padding: "0.5rem",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {props.data.wp.pressItems?.edges !== null && (
                    <>
                      <div
                        style={{
                          fontFamily: fontStacks.merriweather,
                          fontSize: "2rem",
                          marginBottom: "3rem",
                        }}
                      >
                        Pers.
                      </div>

                      <div
                        style={{
                          padding: "1.5rem 0",
                          display: "flex",
                          overflowX: "auto",
                          WebkitOverflowScrolling: "touch",
                          scrollSnapType: "x mandatory",
                        }}
                        css={`
                          a + a {
                            margin-left: 3rem;
                          }
                          .card {
                            scroll-snap-align: start;
                            background-color: ${colors.aluminum};
                            color: ${colors.superiorWhite};
                            min-width: 14rem;
                            min-height: 18rem;
                            transition: 0.25s;
                          }

                          .card:hover,
                          .card:focus {
                            transform: translate(0px, -10px);
                            box-shadow: 15px 15px 20px
                              ${transparentize(0.33, colors.carbon)};
                          }
                          // .card > .hoverContent {
                          //   display: none;
                          // }

                          // .card:hover > .hoverContent {
                          //   display: block !important;
                          // }

                          // .card:hover > .normalContent {
                          //   display: none;
                          // }
                        `}
                      >
                        {props.data.wp.pressItems?.edges?.map(
                          (edge, i) =>
                            edge?.node && (
                              <Card
                                key={i}
                                img={
                                  edge.node.featuredImage?.localFile.childImageSharp
                                    .fluid as FluidObject
                                }
                                link={`/${edge.node.uri}`}
                                title={edge.node.title as string}
                                subtitle=""
                              />
                            )
                        )}
                      </div>
                    </>
                  )}

                  {props.data.wp.publicationPages?.edges !== null && (
                    <>
                      <div
                        style={{
                          fontFamily: fontStacks.merriweather,
                          fontSize: "2rem",
                          margin: "3rem 0",
                        }}
                      >
                        Publicaties.
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          border: borders.green,
                        }}
                        css={`
                          a + a {
                            margin-left: 3rem;
                          }
                          .card {
                            scroll-snap-align: start;
                            background-color: ${colors.aluminum};
                            color: ${colors.superiorWhite};
                            min-width: 14rem;
                            min-height: 18rem;
                            transition: 0.25s;
                          }

                          .card:hover,
                          .card:focus {
                            transform: translate(0px, -10px);
                            box-shadow: 15px 15px 20px
                              ${transparentize(0.33, colors.carbon)};
                          }
                          // .card > .hoverContent {
                          //   display: none;
                          // }

                          // .card:hover > .hoverContent {
                          //   display: block !important;
                          // }

                          // .card:hover > .normalContent {
                          //   display: none;
                          // }
                        `}
                      >
                        {props.data.wp.publicationPages?.edges?.map(
                          (edge, i) =>
                            edge?.node && (
                              <Card
                                key={i}
                                img={
                                  props.data.cardlogo?.childImageSharp
                                    ?.fluid as FluidObject
                                }
                                link={`/${edge.node.uri}`}
                                title={edge.node.title as string}
                                subtitle=""
                              />
                            )
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        );
      }}
    </ReactResizeDetector>
  );
};

function dateFromString(date) {
  return new Date(
    date
      .split("/")
      .reverse()
      .join("-")
  );
}

export default PageNieuws;

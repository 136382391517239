import React, { FC } from "react";
import Img, { FluidObject } from "gatsby-image";
import { Link } from "gatsby";

import { fontStacks } from "../global-style";

interface IProps {
  title?: String;
  subtitle?: String;
  img?: FluidObject;
  country?: String;
  sector?: String;
  since?: String;
  link: any;
}

export const Card: FC<IProps> = props => {
  const { img, link, title, subtitle, country, sector, since }: IProps = props;

  return (
    <>
      <Link to={link}>
        <div
          className={"card"}
          style={{
            display: "flex",
            flexDirection: "column",
            position: "relative",
          }}
        >
          {img ? (
            <Img
              className="normalContent"
              style={{ margin: "auto 1rem", filter: "grayscale()" }}
              fluid={img}
            />
          ) : (
            ""
          )}
          <div
            className="normalContent"
            style={{ bottom: 0, position: img ? "absolute" : "inherit" }}
          >
            <div
              style={{
                margin: "0rem 1rem",
                fontFamily: fontStacks.merriweather,
                fontSize: "1.1rem",
              }}
            >
              {title}
            </div>
            <div style={{ margin: "0rem 0.5rem 0.5rem 1rem" }}>{subtitle}</div>
          </div>
          {/* <div
            className="hoverContent"
            style={{ margin: "auto 1rem", display: "none" }}
          >
            <div
              style={{
                fontFamily: fontStacks.merriweather,
                fontSize: "1.1rem",
              }}
            >
              {title}
            </div>
            <div>{subtitle}</div>
            <div>
              <div style={{ lineHeight: "1.25rem" }}>{country}</div>
              <div style={{ lineHeight: "1.25rem" }}>{sector}</div>
              <div style={{ lineHeight: "1.25rem" }}>{since}</div>
            </div>
          </div> */}
        </div>
      </Link>
    </>
  );
};

import React, { FC } from "react";
import { Link } from "gatsby";
import Img, { FluidObject } from "gatsby-image";

import { fontStacks, colors, borders } from "../global-style";

interface IProps {
  img?: FluidObject | FluidObject[];
  content?: String;
  title?: String;
  date: String;
  link?: any;
}

export const HorizontalCard: FC<IProps> = props => {
  const { img, content, title, date, link }: IProps = props;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "4rem",
        borderBottom: borders.green,
        borderTop: borders.green,
      }}
    >
      <div
        style={{
          flexBasis: "31.25rem",
          paddingRight: "1rem",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            fontFamily: fontStacks.merriweather,
            fontSize: "2rem",
            marginBottom: "1rem",
          }}
        >
          {title}
        </div>
        <div style={{ marginBottom: "1rem" }}>Gepubliceerd op {date}</div>
        <div
          style={{ marginBottom: "1rem" }}
          dangerouslySetInnerHTML={{ __html: content }}
        />
        <div
          style={{ margin: "0.25rem 0.25rem 0.25rem 0rem", marginTop: "auto" }}
        >
          <Link
            style={{
              backgroundColor: colors.sandal,
              color: colors.superiorWhite,
              padding: "0.5rem 1rem",
            }}
            to={link}
          >
            Lees meer
          </Link>
        </div>
      </div>
      <div id={"display-toggle"} style={{ flexBasis: "18.75rem" }}>
        {img ? (
          <Img fluid={img} />
        ) : (
          <pre>Nog geen uitgelichte afbeelding gekozen</pre>
        )}
      </div>
    </div>
  );
};
